<template>
  <div>
    <Chart :cdata="cdata" />
  </div>
</template>

<script>
import Chart from './chart.vue';
export default {
  data () {
    return {
      cdata: {
        xData: ["个护清洁", "蔬菜蛋品", "智能数码", "居家日用", "饮料冲调", "粮油调味"],
        seriesData: [
          { value: 10, name: "个护清洁" },
          { value: 5, name: "蔬菜蛋品" },
          { value: 15, name: "智能数码" },
          { value: 25, name: "居家日用" },
          { value: 20, name: "饮料冲调" },
          { value: 35, name: "粮油调味" }
        ]
      }
    }
  },
  components: {
    Chart,
  },
  mounted () {
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
</style>
