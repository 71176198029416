<template>
  <div>
    <Chart :cdata="cdata" />
  </div>
</template>

<script>
import Chart from './chart.vue';
export default {
  data () {
    return {
      cdata: [
        {
          name: '回民区',
          value: 10,
          elseData:{
            // 这里放置地图 tooltip 里想显示的数据
          }
        },
        {
          name: '玉泉区',
          value: 9,
        },
        {
          name: '新城区',
          value: 8,
        },
        {
          name: '赛罕区',
          value: 7,
        },
        {
          name: '托克托县',
          value: 6,
        },
        {
          name: '清水河县',
          value: 5,
        },
        {
          name: '武川县',
          value: 4,
        },
        {
          name: '和林格尔县',
          value: 3,
        },
        {
          name: '土默特左旗',
          value: 2,
        }
      ]
    }
  },
  components: {
    Chart,
  },
  mounted () {
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
</style>
