<template>
  <div id="centreRight1">
    <div class="bg-color-black">
      <div class="d-flex pt-2 pl-2">
        <span style="color:#5cd9e8">
          <icon name="chart-line"></icon>
        </span>
        <div class="d-flex">
          <span class="fs-xl text mx-2">住宅物业服务企业排名</span>
        </div>
      </div>
      <div class="d-flex jc-center body-box">
        <dv-scroll-board :config="config" style="width:6.975rem;height:4.28rem" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      config: {
        header: ["物业公司名称", "项目数量", "面积数量万平米"],
        data: [
          ["内蒙古瑞诚物业服务有限公司", "42", "382.5"],
          ["呼和浩特市日富物业管理有限责任公司", "38", "652.3"],
          ["内蒙古佳荣物业服务有限责任公司", "30", "265.2"],
          ["呼和浩特市星宸物业管理有限公司", "29", "206.5"],
          ["呼和浩特市日信海清物业服务有限责任公司", "25", "200.6"],
          ["呼和浩特市嘉诚物业服务有限责任公司", "24", "160.8"],
          ["呼和浩特市和德物业服务有限公司", "23", "85"],
          ["内蒙古仁和服务股份有限公司", "23", "130.8"],
          ["内蒙古海泽物业服务有限公司", "21", "260.5"],
        ],
        rowNum: 7, //表格行数
        headerHeight: 35,
        headerBGC: "#0f1325", //表头
        oddRowBGC: "#0f1325", //奇数行
        evenRowBGC: "#171c33", //偶数行
        index: true,
        columnWidth: [50,300,100,150],
        align: ["center"]
      }
    };
  },
  components: {},
  mounted() {},
  methods: {}
};
</script>

<style lang="scss">
#centreRight1 {
  padding: 0.2rem;
  height: 5.125rem;
  min-width: 3.75rem;
  border-radius: 0.0625rem;
  .bg-color-black {
    height: 4.8125rem;
    border-radius: 0.125rem;
  }
  .text {
    color: #c3cbde;
  }
  .body-box {
    border-radius: 0.125rem;
    overflow: hidden;
  }
}
</style>
