<template>
  <div>
    <Chart :cdata="cdata" />
  </div>
</template>

<script>
import Chart from './chart.vue'
export default {
  data () {
    return {
      cdata: {
        category: [
          "回民区",
          "玉泉区",
          "新城区",
          "赛罕区",
        ],
        lineData: [
          36097,
          39867,
          44715,
          48444,
          50415,
          50061,
          32677,
          49521,
          32808
        ],
        barData: [
          32097,
          34867,
          24715,
          48444,
          30415,
          30061,
          22677,
          39521,
          42808
        ],
        rateData: [
          38097,
          37867,
          34715,
          42444,
          30415,
          40061,
          42677,
          29521,
          32808
        ]
      }
    };
  },
  components: {
    Chart,
  },
  mounted () {
    this.setData();
  },
  methods: {
    // 根据自己的业务情况修改
    setData () {
      for (let i = 0; i < this.cdata.barData.length -1; i++) {
        let rate = this.cdata.barData[i] / this.cdata.lineData[i];
        this.cdata.rateData.push(rate.toFixed(2));
      }
    },
  }
};
</script>

<style lang="scss" scoped>
</style>
